'use strict';

import $ from "jquery";
import 'bootstrap';

// Owl Carousel
import "owl.carousel2";
import "owl.carousel2/dist/assets/owl.carousel.min.css";

// Tiny slider (Carousel)
import { tns } from "tiny-slider/src/tiny-slider";

//jQuery validate
import "jquery-validation";
import "jquery-validation/dist/additional-methods.js"

// Select 2
import "select2";
import "select2/dist/css/select2.min.css";

// Easyzoom
import "easyzoom";
import "easyzoom/css/easyzoom.css";

// Magnific popup
// import "magnific-popup";
// import "magnific-popup/dist/magnific-popup.css";

// Leaflet
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import icon from '../img/site/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import '../sass/main.scss';

// Toastr
import toastr from "toastr";
import "toastr/toastr.scss";

// lightgallery
import lightgallery from "lightgallery";
import "lightgallery/dist/js/lightgallery.js";
import "lightgallery/dist/css/lightgallery.css";
import "lightgallery/modules/lg-thumbnail.js";

$(function() {

    // Gloabal config
    toastr.options.timeOut      = 15000;
    toastr.options.closeButton  = true;

    $('[data-toggle="tooltip"]').tooltip();

    $('.btn-connexion').click(function() {
        $('#modalClientConnexion').modal('show');
    });

    // Initialisation du bloc-carousel
    $('.bloc-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoplay: true,
        navText: [
            "<i class='fal fa-chevron-left'></i>",
            "<i class='fal fa-chevron-right'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    })

    //Initialisation des galeries
    $("#lightgallery").lightGallery();

    /* Video Modal*/
    var $videoSrc;
    $('#videoModalButton').click(function() {
        $videoSrc = $(this).data( "src" );
    });

    // when the modal is opened autoplay it
    $('#videoModal').on('shown.bs.modal', function (e) {

    // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" );
    })

// stop playing the youtube video when I close the modal
    $('#myModal').on('hide.bs.modal', function (e) {
        // a poor man's stop video
        $("#video").attr('src',$videoSrc);
    })
    $('.scrollto').click(function() {
        var anchor = $(this.hash);
        if (anchor.length) {
            $("html, body").animate({ scrollTop: anchor.offset().top }, 1000);
        }
    });

    if ($('#mapid').length) {
        chargerMap();
    }

    $('#filtre-produit').select2({
        placeholder: "-- Sélectionner un produit --"
    });

    $('.easyzoom').each(function() {
        var $easyzoom = $(this).easyZoom({
            loadingNotice: 'Chargement'
        });
        var easyzoomAPI = $easyzoom.data('easyZoom');
        $('.thumbnails a').click(function(event) {
            event.preventDefault();
            var $elm = $('.galerie.active').prev('.easyzoom').find('a');
            easyzoomAPI.swap($(this).data('standard'), $(this).attr('href'));
        });
    });

    $('.owl-carousel-three-columns').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplayTimeout: 8000,
        autoplaySpeed: 1000,
        autoplayHoverPause: true,
        navSpeed: 1000,
        navText: [
            "<i class='fal fa-chevron-left'></i>",
            "<i class='fal fa-chevron-right'></i>"
        ],
        responsive:{
            0:{
                items:1,
            },
            576:{
                items:1,
            },
            768:{
                items:2,
            },
            992:{
                items:3,
                nav: true,
                dots: false,
            },
            1200:{
                items:3,
                nav: true,
                dots: false,
            }
        }
    });

    $('.owl-carousel-thumb-produit').owlCarousel({
        loop: false,
        autoplay: false,
        margin: 15,
        navSpeed: 1000,
        items: 3,
        navText: [
            "<i class='fal fa-chevron-left'></i>",
            "<i class='fal fa-chevron-right'></i>"
        ],
        responsive:{
            0:{
                nav: false,
                dots: true,
            },
            576:{
                nav: true,
                dots: false,
            }
        }
    });

    $('.flash-message').each(function() {
        var type = $(this).data('type');
        var text = $(this).text();

        if ('success' == type)
            toastr.success(text);
        if ('error' == type)
            toastr.error(text);
        if ('warning' == type)
            toastr.warning(text);

        $(this).remove();
    });

    $('form.ajaxForm').submit(function(){
        var $elm = $(this);
        var $btn = $elm.find('button');

        var loadingText = '<i class="fas fa-spinner-third fa-spin"></i>';
        var buttonText  = $btn.text();

        $btn.prop('disabled', true);
        $btn.html(loadingText + ' ' + buttonText);

        $.ajax({
            type: $elm.attr('method'),
            url: $elm.attr('action'),
            data: $elm.serialize()
        })
        .done(function (donnees) {
            if (donnees.success) {
                if (donnees.redirect) {
                    window.location.href = donnees.redirect;
                }

                if (donnees.message) {
                    toastr.success(donnees.message);
                    $elm.find('.form-control').val('');
                }

                if (donnees.callback) {
                    eval(donnees.callback)(donnees);
                }
            } else {
                toastr.error(donnees.errors);
            }

            $btn.prop('disabled', false);
            $btn.html(buttonText);
        });

        return false;
    });


    $('#valider-cgv').click(function() {
        if (false == $('#cgvCheck').is(':checked')) {
            toastr.error('Veuillez valider les CGV pour poursuivre votre commande');
            return false;
        }

        return true;
    });

    $('#hoplie_userbundle_client_email_second, #hoplie_userbundle_client_plainPassword_second').bind("cut copy paste",function(e) {
        e.preventDefault();
    });


    // Supprime complement la modal lorsqu'elle est masquée
    $(document).on('hidden.bs.modal', '#basketModal, #formulaireAdresse, #contactModal', function () {
        $('#basketModal').remove();
        $('#formulaireAdresse').remove();
        //$('#contactModal').remove();
    });

    $.validator.addMethod('customphone', function (value, element) {
        return this.optional(element) || /^[\d\s()+-]+$/.test(value);
    }, "Veuillez saisir un numéro de téléphone valide");
});



function chargerMap() {
    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [24,36],
        iconAnchor: [12,36],
        popupAnchor: [0, -30]
    });

    L.Marker.prototype.options.icon = DefaultIcon;

    var mymap = L.map('mapid').setView([46.568538,0.270842], 13);
    var adresse = $('.coordonnees-adresse').html();
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaG9wbGllIiwiYSI6ImNqcXFlNmF3MDBhZzY0M3FnanBlb2Y2ejMifQ.qEI7PuY-ETsk7vCxWlFRZg', {
        attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
        tileSize: 512,
        maxZoom: 18,
        zoomOffset: -1,
        id: 'mapbox/streets-v11',
        accessToken: 'YOUR_MAPBOX_ACCESS_TOKEN'
    }).addTo(mymap);
    L.marker([46.568538,0.270842]).addTo(mymap)
        .bindPopup(adresse).openPopup();
}

// Submit des formulaires
$('.js-formulaire-inscription-newsletter').on('submit', function (e) {
    e.preventDefault();
    $.ajax({
        method: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        success: (function (response, status, xhr) {
            if (response.success) {
                toastr.success(response.message);
            }
            if (response.success == false) {
                toastr.error(response.errors);
            }
        })
    });
});

$('.js-formulaire-desinscription-newsletter').on('submit', function (e) {
    e.preventDefault();
    $.ajax({
        method: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        success: (function (response, status, xhr) {
            if (response.success) {
                toastr.success(response.message);
            }
            if (response.success == false) {
                toastr.error(response.errors);
            }
        })
    });
});
